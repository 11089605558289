<template>
  <v-card class="mt-4 mx-auto">
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-sheet
      class="v-sheet--offset mx-auto"
      color="success"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <v-card
        dark
        color="color_green"
      >
        <v-card-title> CUSTOMER </v-card-title>
      </v-card>
    </v-sheet>
    <v-card-text class="pt-0">
      <v-data-table
        id="customer-table"
        v-model="selected"
        :headers="customer.headers"
        :items="customer.customers"
        :search="search"
        :loading="loading"
        class="elevation-1"
        item-key="crm_id"
        show-select
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
            <v-spacer />
            <template>
              <v-btn
                color="primary"
                width="50"
                @click="_switchState()"
              >
                State
              </v-btn>
            </template>
            <v-spacer />
            <v-btn
              color="color_green"
              dark
              class="mb-2"
              @click="newCustomerButtonEvent"
            >
              New Customer
            </v-btn>
          </v-toolbar>
        </template>
        <template
          v-slot:[`item.c_description`]="{ item }"
          class="d-grid"
        >
          <span
            class="d-inline-block text-truncate"
            style="max-width: 30em"
          >
            {{ item.c_description }}
          </span>
        </template>
        <template v-slot:[`item.state`]="{ item }">
          <v-chip
            :color="item.state === true ? 'color_green' : '#C70039'"
          />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="pencilButtonEvent(item)"
          >
            mdi-pencil
          </v-icon>
          <delete
            :id="item.name"
            @deleted="deleteItem(item.crm_id)"
          />
        </template>
        <template v-slot:no-data>
          <span color="primary">No data to show you</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    delete: () => import('@/components/utils/Delete')
  },
  data() {
    return {
      search: '',
      selected: [],
      cleanItem: {
        crm_id: '',
        ragione_sociale: '',
        c_description: ''
      },
      loading: false,
      overlay: false
    };
  },
  computed: {
    ...mapState(['customer', 'tech_user', 'app_user', 'services', 'ticketing']),
    target() {
      const value = 9999;

      return value;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic'
      };
    }
  },

  created() {
    this.loading = true;
    this.storeCustomers();
    this.loading = false;
  },

  methods: {
    ...mapActions([
      'changeEditCustomer',
      'storeCustomers',
      'deleteTableCustomer',
      'switchCustomerState'
    ]),
    newCustomerButtonEvent() {
      this.overlay = true;
      this.$emit('closeDialog');

      if (this.customer.editCustomer == false) {
        this.changeEditCustomer(false);
      }

      this.customer.isModifingCustomer = false;
      this.emitTableItem(this.cleanItem);
      this.$store.state.disableTab = true;
      this.$vuetify.goTo(this.target, this.options);
      this.overlay = false;
    },

    async pencilButtonEvent(item) {
      this.overlay = true;
      this.$emit('closeDialog');

      if (this.customer.editCustomer == false) {
        this.changeEditCustomer(true);
      }

      this.customer.isModifingCustomer = true;
      this.$store.state.disableTab = false;

      this.emitTableItem(item);
      this.$vuetify.goTo(this.target, this.options);
      this.overlay = false;
    },

    emitTableItem(item) {
      this.$emit('toParent', item);
    },

    async deleteItem(crm_id) {
      this.overlay = true;
      await this.deleteTableCustomer(crm_id);
      this.overlay = false;
      this.$emit('closeDialog');
    },

    async _switchState() {
      this.overlay = true;
      await this.switchCustomerState(this.selected);
      this.storeCustomers();
      this.overlay = false;
      this.selected = [];
      this.$emit('renderCustomerForm');
    }
  }
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
